import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import Notifications from 'notiwind'
import Vue3Apexcharts from "vue3-apexcharts";
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import Popper from 'vue3-popper';
import * as Sentry from "@sentry/vue";
import {createI18n} from 'vue-i18n';

const datetimeFormats = {
    'es': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        short_time: {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
        },
        long_time: {
            year: 'numeric', month: 'long', day: 'numeric', weekday: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric'
        },
        month: {
            month: 'long',
        },
        time: {
            hour: 'numeric', minute: 'numeric', hour12: true
        },
    }
};

const i18n = createI18n({
    datetimeFormats,
    locale: "es",
});

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)});

        Sentry.init({
            app,
            environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            trackComponents: true,
        });

        return app
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(Notifications)
            .use(Vue3Apexcharts)
            .use(Popper)
            .use(i18n)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
